import { themeGet } from "@styled-system/theme-get"
import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { BlockContent } from "../components/content/block-content"
import { Box } from "../components/core"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

export const pageQuery = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      description
      keywords
    }
    page: sanityPage(_id: { eq: "about" }) {
      title
      _rawBody
    }
  }
`

export default function ProductPage({ data: { site, page } }) {
  const { description, keywords } = site
  const { title, _rawBody } = page

  return (
    <Layout>
      <SEO title={title} description={description} keywords={keywords} />
      <Box as="h1" textAlign="center">
        {title}
      </Box>
      <ContentWrapper>
        <Box as="h2" fontSize={[5]} fontWeight={[2]} lineHeight={[1]} mt={["8px"]}>
          It’s 2021, and we need to talk about health and technology.
        </Box>
        <BlockContent blocks={_rawBody} />
      </ContentWrapper>
    </Layout>
  )
}

const ContentWrapper = styled(Box).attrs(() => ({
  mt: ["32px", "80px"],
  mb: ["120px", "200px"],
}))`
  @media (min-width: ${themeGet("breakpoints.md")}) {
    display: grid;
    grid-gap: 64px;
    grid-template-columns: 1fr 60%;
  }
`
